<template>

    <div>
        <template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>

                  <b-card>
          
                    <b-form>
                <b-row>
              <b-col md="6">
        <b-form-group label="User Name">
                  <v-select 
                    v-model="filter_user_name"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="users"
                    multiple
                    :options="users"
                    placeholder="Select Client"
                    @input="filterTable"
                  />
          </b-form-group>
              </b-col>

            <b-col md="6">
            <b-form-group label="Status">
                  <v-select 
                    v-model="selected_user_status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="filter_user_status"
                    :options="filter_user_status"
                    placeholder="Select Status"
                    @input="filterTable"
            />
          </b-form-group>   
                </b-col>
                </b-row>
                 <b-row>
                <b-col md="6">
            <b-form-group label="Account Type">
                  <v-select 
                    v-model="selected_account_type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="filter_account_type"
                    :options="filter_account_type"
                    placeholder="Select Account Type"
                    @input="filterTable"
            />
          </b-form-group>   
                </b-col>
                 </b-row>

              <b-row>
              <b-col md="12" class="d-flex align-items-center justify-content-center justify-content-sm-center">

        <b-button variant="warning" @click="resetFilter" v-if="filter_user_name != '' || selected_user_status != null || selected_account_type != null">
            <span>Reset Filter</span>
        </b-button>
              </b-col>
              </b-row>

          
            </b-form>

                </b-card>
        <!-- Table Container Card -->
        <b-card no-body class="mb-0">

            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="2" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <b-form-select 
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                            @input="filterTable"
                        />
                        <label>entries</label>
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="8">
                        <div class="d-flex align-items-center justify-content-center mt-1">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1"
                                placeholder="Search"
                                @input="filterTable"
                            />
                        </div>
                    </b-col>
                    <b-col cols="12" md="2">
                        <div class="d-flex align-items-cebter justify-content-center">
                            <b-button variant="warning" class="mt-1" @click="createUserOnClick()">
                                <span class="text-nowrap">Create New User</span>
                             </b-button>
                        </div>
                    </b-col>

                </b-row>
            </div>

            <b-table sticky-header
                responsive
                show-empty
                ref = "refUserListTable"
                class = "position-relative smallTable"
                primary-key = "id"
                empty-text = "No matching records found"
                :fields = "tableColumns"
                :sort-by.sync = "sortBy"
                :items = "dataList"
                :sort-desc.sync = "isSortDirDesc"
            >
                <template #cell(user_tag)="items">
                    <p class="">{{ items.item.user_tag | capitalize}}</p>
                </template>

                <template #cell(user_name)="items">
                    <p class="">{{ items.item.user_name }}</p>
                </template>

                <template #cell(user_address)="items">
                    <p class="">{{ items.item.user_address }}</p>
                </template>

                <template #cell(user_email)="items">
                    <p class="">{{ items.item.user_email }}</p>
                </template>

                <template #cell(user_contact)="items">
                    <p class="">{{ items.item.user_contact}}</p>                 
                </template>

                <template #cell(user_uen)="items">
                    <p class="">{{ items.item.user_uen}}</p>                 
                </template>

                <template #cell(user_status)="items">
            <b-badge
              pill
              :variant="variantColor(items.item.user_status)"
              class="text-capitalize"
            >
              {{ items.item.user_status}}
            </b-badge>             
                </template>

                <template #cell(actions)="items">

                    <div>
                         <span>
            <b-dropdown
              variant="link"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
            <b-dropdown-item @click="openModal('View', items.item)">
                <feather-icon
                  icon="SearchIcon"
                  class="mr-50"
                />
                <span>View User</span>
              </b-dropdown-item>
              <b-dropdown-item @click="editUserOnClick(items.item)">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Edit User</span>
              </b-dropdown-item>
            <b-dropdown-item @click="openModal('Password', items.item)" v-if="items.item.user_status != 'Unverified'">
                <feather-icon
                  icon="UserIcon"
                  class="mr-50"
                />
                <span>Change Password</span>
              </b-dropdown-item>
             <b-dropdown-item @click="openModal('Password', items.item)" v-if="items.item.user_status == 'Unverified'">
                <feather-icon
                  icon="UserIcon"
                  class="mr-50"
                />
                <span>Verify User</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteUser(items.item._id, items.item.user_name)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete User</span>
              </b-dropdown-item>

            </b-dropdown>
          </span>
                    </div>

                </template>

            </b-table>

            <div class="mx-2 mb-2">
                <b-row>
            <b-col
            cols="12"
            sm="4"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
          </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="4" class="d-flex align-items-center justify-content-center justify-content-sm-center">
                        <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRecords"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                        @input="filterTable"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18"/>
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18"/>
                            </template>
                        </b-pagination>
                    </b-col>

                </b-row>
            </div>
        </b-card>

            <!-- Create New User Modal -->
        <b-modal 
            id="create-user" centered ref="create-user" ok-only
            ok-variant="warning" header-bg-variant="warning" footer-class="justify-content-center"
            ok-title="Submit" 
            @ok="submitForm"
            :title="title"
            no-close-on-backdrop
            size="lg"
        >
            <b-alert variant="danger" show>
                <div class="alert-body"> {{error_message}} </div>
            </b-alert>
            <b-form>
                <b-row>                    
                    <b-col md="12">
                        <b-form-group label="Name" class="required">              
                            <b-form-input placeholder="" type="text" v-model="form.name" autocomplete="off"/>
                        </b-form-group>
                    </b-col>
                    
                    <b-col md="12">
                        <b-form-group label="Email" class="required">   
                        <b-form-input placeholder="" type="email" v-model="form.user_email" autocomplete="off"/>
                        </b-form-group>
                    </b-col>
                    
                    <b-col md="12">
                        <b-form-group label="Handphone">
                            <b-form-input placeholder="" type="text" v-model="form.user_handphone" autocomplete="off"/>
                        </b-form-group>
                    </b-col>

               <b-col md="12">
                        <b-form-group label="User Name" class="required">
                            <b-form-input placeholder="" type="text" v-model="form.user_name"/>
                </b-form-group>
               </b-col>

                <b-col md="12">
                        <b-form-group label="Account Type" class="required">              
                             <b-form-select v-model="form.account_type">

                             <b-form-select-option value="">Select</b-form-select-option>
                    
                          <b-form-select-option :value="type.name" v-for="type in account_type" :key="type._id">{{type.name}}</b-form-select-option>

                             </b-form-select>
                        </b-form-group>
                </b-col>  

                </b-row>      
            </b-form>

        </b-modal>
        <!-- View User Modal -->
        <b-modal 
            id="view-user" centered ref="view-user" header-bg-variant="warning" 
            @hidden="ResetData"
            :title="title"
            no-close-on-backdrop
            hide-footer
            size="lg"
        >
            <b-alert variant="danger" show>
                <div class="alert-body"> {{error_message}} </div>
            </b-alert>
            <b-form>
                <b-row>                  
                    <b-col md="12">
                        <b-form-group label="Name" class="required">              
                            <b-form-input placeholder="" type="text" v-model="form.name" disabled autocomplete="off"/>
                        </b-form-group>
                    </b-col>
                    
                    <b-col md="12">
                        <b-form-group label="Email" class="required">   
                        <b-form-input placeholder="" type="email" v-model="form.user_email" disabled autocomplete="off"/>
                        </b-form-group>
                    </b-col>
                    
                    <b-col md="12">
                        <b-form-group label="Handphone">
                            <b-form-input placeholder="" type="text" v-model="form.user_handphone" disabled autocomplete="off"/>
                        </b-form-group>
                    </b-col>

                    <b-col md="12">
                        <b-form-group label="Status" class="required">
                            <b-form-input placeholder="" type="text" v-model="form.user_status" disabled autocomplete="off"/>
                        </b-form-group>
                    </b-col>

                    <b-col md="12">
                        <b-form-group label="Halal?">
                            <b-form-input placeholder="" type="text" v-model="form.halal_status" disabled autocomplete="off"/>
                        </b-form-group>
                    </b-col>

                    <b-col md="12">
                        <b-form-group label="Assigned Items"/>
                    </b-col>

                    <b-col cols="12" md="6" v-for="item in selected_item" :key="item._id">                    
                        <b-form-group>
                        <b-col md="12" >
                        <div class="d-inline pr-1">
                            <span class="badge badge-pill badge-success"> {{item.item_name}} </span>
                        </div>
                        </b-col>
                        </b-form-group>
                    </b-col>

                    </b-row>

            </b-form>

        </b-modal>
        <!-- Edit User Modal -->
        <b-modal 
            id="edit-user" centered ref="edit-user" ok-only
            ok-variant="warning" header-bg-variant="warning" footer-class="justify-content-center"
            ok-title="Submit" 
            @ok="updateForm(id)"
            @hidden="ResetEditData"
            :title="title"
            no-close-on-backdrop
            size="lg"
        >
            <b-alert variant="danger" show>
                <div class="alert-body"> {{error_message}} </div>
            </b-alert>
            <b-form>
                <b-row>              
                    <b-col md="12">
                        <b-form-group label="Name" class="required">              
                            <b-form-input placeholder="" type="text" v-model="edit_form.name" autofocus autocomplete="off"/>
                        </b-form-group>
                    </b-col>
                    
                    <b-col md="12">
                        <b-form-group label="Email" class="required">   
                        <b-form-input placeholder="" type="email" v-model="edit_form.user_email" autocomplete="off"/>
                        </b-form-group>
                    </b-col>
                    
                    <b-col md="12">
                        <b-form-group label="Handphone">
                            <b-form-input placeholder="" type="text" v-model="edit_form.user_handphone" autocomplete="off"/>
                        </b-form-group>
                    </b-col>

                    <b-col md="12">
                        <b-form-group label="User Name" class="required">   
                        <b-form-input placeholder="" type="text" v-model="edit_form.user_name" autocomplete="off"/>
                        </b-form-group>
                    </b-col>

                    <b-col md="12">
                        <b-form-group label="Account Type" class="required">              
                            <b-form-input placeholder="" type="text" v-model="edit_form.account_type" disabled autocomplete="off"/>
                        </b-form-group>
                    </b-col>  

                    <b-col md="12">
                        <b-form-group label="Status" class="required">
                            
                             <b-form-select v-model="edit_form.user_status">
                    
                          <b-form-select-option :value="type.name" v-for="type in user_status" :key="type._id">{{type.name}}</b-form-select-option>
                             </b-form-select>
                        </b-form-group>
                    </b-col>

                    <b-col md="12">
                        <b-form-group label="Halal?" v-if="form.options.includes('halal') && edit_form.vegetarian_status != 'yes' && edit_form.account_type != 'Admin'" class="required"> 
                             <b-form-select v-model="edit_form.halal_status" @change="onHalalChange(edit_form.halal_status)">
                    
                          <b-form-select-option value="yes">Yes</b-form-select-option>
                          <b-form-select-option value="no">No</b-form-select-option>
                             </b-form-select>
                        </b-form-group>
                    </b-col>

                    <b-col md="12">
                        <b-form-group label="Assign Items" class="required" v-if="edit_form.account_type != 'Admin' && (edit_form.vegetarian_status == 'no' || edit_form.vegetarian_status == null) ">              
                        <v-select 
                         v-model="selected_item"
                         :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="filter_itemlist"
                        multiple
                        :options="filter_itemlist"
                        placeholder="Select Item or type to search"
                        :getOptionLabel="option => option.item_name"
                        @input="selectAll($event)"
                         />
                        </b-form-group>
                    </b-col>  

                    <b-col md="12">
                        <b-form-group label="Assign Vegetarian Items" class="required" v-if="(edit_form.vegetarian_status != 'no' && edit_form.vegetarian_status != null) && edit_form.account_type != 'Admin'">              
                        <v-select 
                         v-model="selected_item"
                         :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="filter_itemlist"
                        multiple
                        :options="filter_itemlist"
                        placeholder="Select Item or type to search"
                        :getOptionLabel="option => option.item_name"
                        @input="selectAll($event)"
                         />
                        </b-form-group>
                    </b-col>  

                     <b-col md="12" class="text-center" v-if="selected_item.length != 0">
                         <b-button variant="danger" @click="clearSelectedItems()">
                            <span>Clear Assigned Items</span>
                         </b-button>
                    </b-col>  

                </b-row>      
            </b-form>

        </b-modal>
    <!-- Change Password Modal -->
        <b-modal 
            id="change-password" centered ref="change-password" ok-only
            ok-variant="warning" header-bg-variant="warning" footer-class="justify-content-center"
            ok-title="Submit" 
            @ok="changePassword(id)"
            @hidden="ResetPassword()"
            :title="title"
            no-close-on-backdrop
            size="lg"
        >
            <b-alert variant="danger" show>
                <div class="alert-body"> {{error_message}} </div>
            </b-alert>
            <b-form>
                <b-row>                    
                    <b-col clos="12" md="6">
                        <b-form-group label="New Password" class="">
                        <b-input-group
                            class="input-group-merge">
                            <b-form-input placeholder="Enter New Password" v-model="new_password" :type="passwordFieldType" class="form-control-merge"/>
                            <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                        </b-input-group>
                        </b-form-group>
                    </b-col>

                      <b-col clos="12" md="6">
                        <b-form-group label="Re-enter New Password" class="">
                            <b-input-group
                            class="input-group-merge">
                            <b-form-input placeholder="Re-enter New Password" v-model="repeat_new_password" :type="cpasswordFieldType" class="form-control-merge"/>
                            <b-input-group-append is-text>
                            <feather-icon
                                class="cursor-pointer"
                                :icon="cpasswordToggleIcon"
                                @click="toggleCPasswordVisibility"
                            />
                  </b-input-group-append>
                        </b-input-group>
                        </b-form-group>
                    </b-col>

                </b-row>      
            </b-form>

        </b-modal>
    </div>
</template>

<script>
import {
    BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip, BFormDatepicker,BFormSelect,BFormSelectOption,BModal,BBreadcrumb, BFormGroup, BForm, BInputGroupPrepend, BInputGroup, BInputGroupAppend, BAlert, BDropdownForm, BFormCheckboxGroup, BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import moment from "moment";
import Bus from "../../../event-bus";
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
    components: {
        BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, vSelect, BFormDatepicker,BFormSelect,BFormSelectOption,BModal,BBreadcrumb, BFormGroup, BForm, VueGoogleAutocomplete, BInputGroupPrepend, BInputGroup, BInputGroupAppend, BAlert,  BDropdownForm, BFormCheckboxGroup, BFormCheckbox
    },
    mixins: [togglePasswordVisibility],
    directives: {
        'b-tooltip': VBTooltip
    },
    data() {
        return {
            webUrl: process.env.VUE_APP_SERVER_URL,
            error_message: null, 
            showDismissibleAlert:false,

            tableColumns: [
                { key: 'actions', label: 'Actions', sortable: false , thStyle:  {width: '5%'}},
                { key: 'user_name', label: 'User Name', sortable: true , thStyle:  {width: '15%'}},
                { key: 'name', labeFl: 'Name', sortable: true , thStyle:  {width: '25%'}},
                { key: 'user_email', label: 'Email', sortable: false , thStyle:  {width: '25%'}},
                { key: 'user_handphone', label: 'Handphone', sortable: false , thStyle:  {width: '15%'}},
                { key: 'account_type', label: 'Account Type', sortable: false , thStyle: {width: '10%'}},
                { key: 'user_status', label: 'Status', sortable: true , thStyle:  {width: '5%'}},
            ],
            // b-table and pagination options
            items           : [],
            isSortDirDesc   : true,
            perPageOptions  : [20, 30, 50, 100],
            perPage         : 20,
            sortBy          : 'id',
            totalRecords    : 0,
            currentPage     : 1,
            searchQuery     : '',
            from            : null,
            to              : null, 
            title: null,
             // Company vars
                form : {
                    user_name: '',
                    company_name: '',
                    name:'',
                    user_name: '',
                    password: '',
                    user_email : '',
                    user_handphone : '',
                    account_type   : '',
                    user_status : '',
                    actions : '',
                    options: '',
                    halal_options: false, // Used to check if user has options for halal
                    halal_status: ''
                },
                edit_form : {
                    id: '',
                    user_name: '',
                    company_name: '',
                    name:'',
                    user_name: '',
                    user_email : '',
                    user_handphone : '',
                    account_type   : '',
                    user_status : '',
                    actions : '',
                    options: '',
                    halal_options: false, // Used to check if user has options for halal
                    halal_status: 'no',
                    vegetarian_options:false,
                    vegetarian_status: 'no'
                },
            account_type: [],
            filter_account_type: [],
            user_status: [],
            filter_user_status: [],
            user_name: [],
            users: [],
            itemlist: [],
            filter_itemlist: [],
            selected_item: [],
            filter_user_name : [],
            selected_user_status: null,
            selected_account_type: null,
            id: '',
            companyData: '',
            accountTypeData: '',
            no_email_change: false,
            new_password: '',
            repeat_new_password: '',
            passwordToggleIcon:'EyeIcon',
            cpasswordToggleIcon:'EyeIcon',
            passwordFieldType:'password',
            cpasswordFieldType:'password',
        }
    },
    computed: {
    },
    methods : {
    toggleCPasswordVisibility(){
      if (this.cpasswordFieldType == 'password') {
          this.cpasswordToggleIcon = 'EyeOffIcon';
          this.cpasswordFieldType = 'text';
      }else{
          this.cpasswordToggleIcon = 'EyeIcon';
          this.cpasswordFieldType = 'password';
      }
    },
    togglePasswordVisibility(){

      if (this.passwordFieldType == 'password') {
          this.passwordToggleIcon = 'EyeOffIcon';
          this.passwordFieldType = 'text';
      }else{
          this.passwordToggleIcon = 'EyeIcon';
          this.passwordFieldType = 'password';
      }
    },
           dataList(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        page          : this.currentPage,
                        keyword       : this.searchQuery,
                        rowsPerPage   : this.perPage,
                        sortBy        : this.sortBy,
                        sortDirection : this.isSortDirDesc,
                        site  : this.$store.getters.currentUser.site,
                        filter_account_type: this.selected_account_type,
                        filter_user_status : this.selected_user_status,
                        filter_user_name : this.filter_user_name,
                    },
                    api: '/api/user-database-records'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.items          = this.$store.getters.getResults.data.docs;
                        this.totalRecords   = this.$store.getters.getResults.data.total;
                        this.perPage        = this.$store.getters.getResults.data.limit;
                        this.from           = this.$store.getters.getResults.data.from
                        this.to             = this.$store.getters.getResults.data.to

                        return this.items;
                    }
                });
            },
        async openModal(id, item){

                if (id === "View")
                {
                    this.title = "View User " + item.user_name;
                    if (item != null)

                    {

                    this.form.account_type = item.account_type;
                    this.form.company_name = item.company_name;
                    this.form.user_name = item.user_name;
                    this.form.name = item.name;
                    this.form.user_email = item.user_email;
                    this.form.user_handphone = item.user_handphone;
                    this.form.user_status = item.user_status;
                    await this.getUserItemsName(item._id);

                    for (var i = 0; i < item.options.length; i++)
                    {
                        if (item.options[i].hasOwnProperty("halal"))
                        {
                            this.form.halal_status = item.options[i].halal;
                        }
                    }
                    this.id = item._id;

                    }

                    this.$refs['view-user'].show();
                }
                else if (id === "Edit")
                {
                    if (item != null)

                    {
                    await this.checkOptions();
                    this.title = "Edit User " + item.user_name

                    this.edit_form.account_type = item.account_type;
                    this.edit_form.company_name = item.company_name;
                    this.edit_form.user_name = item.user_name;
                    this.edit_form.name = item.name;
                    this.edit_form.user_email = item.user_email;
                    this.edit_form.user_handphone = item.user_handphone;
                    this.edit_form.user_status = item.user_status;

                    for (var i = 0; i < item.options.length; i++)
                    {
                        if (item.options[i].hasOwnProperty("halal"))
                        {
                            this.edit_form.halal_status = item.options[i].halal;
                        }
                        else
                        {
                            this.edit_form.halal_status = null;
                        }
                        if (item.options[i].hasOwnProperty("vegetarian"))
                        {
                            this.edit_form.vegetarian_status = item.options[i].vegetarian;
                        }
                        else
                        {
                            this.edit_form.vegetarian_status = null;
                        }
                    }            
                    
                    await this.getItems(item.options);
                    await this.getUserItemsName(item._id);
                    
                    /*if (this.form.options.includes('halal'))
                    {
                        this.edit_form.halal_status = "no"
                    }*/
                    
                    this.id = item._id;
                    }

                    this.$refs['edit-user'].show();
                }
                else if (id === "Password")
                {
                    if (item != null)

                    {
                    this.edit_form.id = item._id;
                    this.edit_form.user_name = item.user_name;
                    this.edit_form.user_status = item.user_status;
                    if (item.user_status == 'Unverified') 
                    {
                        this.title = "Verify User and Set Password for " + this.edit_form.user_name;
                    }
                    else 
                    {
                    this.title = "Change Password for " + this.edit_form.user_name;
                    }
                    }

                    this.$refs['change-password'].show();
                }
                else {
                this.title =  'Create New User';

                if(Object.values(this.form).every(x => x === null || x === '')) //Remove error message if user has not inputted any data in Create function
                {
                    this.error_message = '';
                }

                if (this.error_message == null || this.error_message == "") //Prevent resetting of data if there is error

                {

                this.ResetData();

                }

                this.$refs['create-user'].show();
                }
        },
        filterTable(){
                this.$refs.refUserListTable.refresh();
        },
    getStatusData(){

      return this.$store.dispatch(POST_API, {
           data:{
           },
           api: '/api/get-status'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.user_status = data;

                data.forEach(i => {
                  this.filter_user_status.push(i.status)
                })
                return this.user_status;
            }
        });
    },
    getItems(options){

      return this.$store.dispatch(POST_API, {
           data:{
             site_id : this.$store.getters.currentUser.site,
             options: options,
           },
           api: '/api/get-items-selection'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;

                if (data.length > 0) {

                    var obj = {
                        _id: "all-forms",
                        item_name: 'All'
                    }
                  
                  this.filter_itemlist.unshift(obj);
                }
                
                data.forEach(i => {
                  this.filter_itemlist.push(i)
                })
                return this.itemlist;
            }
        });
    },
    getUserItemsName(id){

      return this.$store.dispatch(POST_API, {
           data:{
             id: id
           },
           api: '/api/get-user-items-name'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
               
                this.selected_item = data;

                return this.selected_item;
            }
        });
    },
    submitForm(){
    
      return this.$store.dispatch(POST_API, {
        data:{
            organization: this.$store.getters.currentUser.organization,
            site: this.$store.getters.currentUser.site,
            company_name : this.form.company_name,
            name :this.form.name,
            user_email : this.form.user_email,
            user_handphone : this.form.user_handphone,
            account_type : this.form.account_type,
            user_name : this.form.user_name.toLowerCase(),
            user_status: 'Active',
        },
        api : '/api/submit-user'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;

              this.errorAlertCF()
              this.openModal();

          } else {
              this.showDismissibleAlert = false;

              //var data = this.$store.getters.getResults.data;

              this.successAlert().then((result) => {

                this.ResetData();

                localStorage.setItem('routeCFCheck','no');

                this.error_message = "";

                this.$refs.refUserListTable.refresh();

              });
          }
      });
          },
    updateForm(id){

        var options = [];

        if (this.edit_form.halal_status)
        {
            options.push({halal: this.edit_form.halal_status})
        }
        if (this.edit_form.vegetarian_status)
        {
            options.push({vegetarian: this.edit_form.vegetarian_status})
        }
    
      return this.$store.dispatch(POST_API, {
        data:{
            id: id,
            user_name: this.edit_form.user_name,
            name: this.edit_form.name,
            user_email: this.edit_form.user_email,
            user_handphone: this.edit_form.user_handphone,
            user_status: this.edit_form.user_status,
            account_type: this.edit_form.account_type,
            options: options,
            items: this.selected_item,
        },
        api : '/api/update-user'
      })
      .then(async () => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;

              await this.errorAlertCF();
              await this.getUserItemsName(id)
              await this.getItems(options);
              this.openModal('Edit')
          } else {
              this.showDismissibleAlert = false;

              //var data = this.$store.getters.getResults.data;

              this.successAlert().then((result) => {

                this.ResetData();

                localStorage.setItem('routeCFCheck','no');

                this.error_message = "";

                this.$refs.refUserListTable.refresh();

              });
          }
      });
          },
        changePassword() {
                this.$store.dispatch(POST_API, {
                    data:{
                        id: this.edit_form.id,
                        user_name: this.edit_form.user_name,
                        new_password: this.new_password,
                        repeat_new_password: this.repeat_new_password,
                        user_status: this.edit_form.user_status
                    },
                    api:'/api/change-password-admin'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        
                        this.errorAlertCF();
                        this.openModal('Password');
                             
                    } else {
                        this.new_password = "";
                        this.repeat_new_password = "";
                    
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            iconColor:'#4c8bf5',
                            title: 'Password successfully changed',
                            showConfirmButton: false,
                            timer: 1500
                        }).then((result) => {

                        localStorage.setItem('routeCFCheck','no');

                        this.error_message = "";

                        this.$refs.refUserListTable.refresh();

                        });
                    }        
                }); 
        },
    allUsers(){
      return this.$store.dispatch(POST_API, {
           data:{
            site  : this.$store.getters.currentUser.site,
           },
           api: '/api/all-users'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.users = [];

                if (data.length > 0) {

                  data.forEach(item => {
                    this.users.push(item.user_name);
                  })
                }

                return data;
            }
        });
    },
    /*checkCompanyName(){
      return this.$store.dispatch(POST_API, {
           data:{
                site_id : this.$store.getters.currentUser.site,
                organization  : this.$store.getters.currentUser.organization,
           },
           api: '/api/get-user-id'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.form.user_name = data;
                return this.form.user_name;
            }
        });
    },*/
    checkOptions(){
      return this.$store.dispatch(POST_API, {
           data:{
                site_id : this.$store.getters.currentUser.site,
           },
           api: '/api/get-user-options'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
               
                this.form.options = data;

                return this.form.halal_options;
            }
        });
    },
  getAccountTypeData(){

      return this.$store.dispatch(POST_API, {
           data:{
           },
           api: '/api/get-account-type'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.account_type = data;

                data.forEach(i => {
                  this.filter_account_type.push(i.name)
                })

                return this.account_type;
            }
        });
    },
    ResetData()
    {
        this.form.company_name = "";
        this.form.name = "";
        this.form.user_email = "";
        this.form.user_handphone = "";
        this.form.account_type = "";
        this.form.user_name = "";
        this.form.user_password = "";
        this.form.options = "";
        this.form.halal_options = false;
        this.selected_item = []
    },
    ResetEditData()
    {
        this.edit_form.halal_status = "no";
        this.edit_form.vegetarian_status = "no";
        this.filter_itemlist = [];
        this.selected_item = [];
    },
    ResetPassword()
    {
        this.new_password = '';
        this.repeat_new_password = '';
    },
    resetFilter()
    {
      this.selected_user_status = null;
      this.filter_user_name = '';
      this.selected_account_type = null
      this.filterTable();
    },
    deleteUser(id, username){
      var msg = 'Are you sure you want to delete the user ' + username + ' ? ';
      var message='User Deleted Successfully.';

      Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'top-center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          id: id,
                          user_status:'Deleted'
                        },
                    api:"/api/delete-user",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            
                            Swal.fire({
                              position: 'center',
                              icon: 'success',
                              title: message,
                              showConfirmButton: false,
                              timer: 1500
                            })
                            
                            this.$refs.refUserListTable.refresh();
                          }
                      });
              }
          })
          .catch(err => {
              
          })
    },
    variantColor(status){
      if (status == 'Active') {
        return 'success';
      }
      else if (status == 'Unverified') {
        return 'warning'; 
      }else{
        return 'danger';
      }
    },
    async createUserOnClick() {
        await this.openModal(null);
    },
    editUserOnClick(item) {
        this.openModal('Edit', item);
    },
    selectAll(e) {

        if (e.length > 0 && e[e.length-1]._id == 'all-forms') {
        {
            this.selected_item = [];
            this.filter_itemlist.forEach(item => {
            if (item._id != 'all-forms') {
              this.selected_item.push(item);
            }
            })
        }
        }
    },
    onHalalChange(halal_status) {
        this.selected_item = [];
        this.filter_itemlist = [];
        this.itemlist = [];
        var item = [{halal: halal_status}]
        this.getItems(item)
    },
    clearSelectedItems() {
        this.selected_item = [];
    },
    breadCrumb(){
          var item = [{
            to:{name:'manage-order'},
            text: 'Manage Order',
          },{
            to:null,
            text:'Users DB',
            active:true
          }];
          return item;
        },
       
    },
    mounted(){
    this.allUsers();
    this.getAccountTypeData();
     //this.allUsersbyOrganizations();
     this.getStatusData();
    }
}
</script>

<style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>